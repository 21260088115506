import React, { useState, useEffect } from "react";
import { FormatExecutionString } from "../../utils";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  Paper,
  Typography,
  Avatar,
} from "@mui/material";
import moment from "jalali-moment";
import {
  Expand,
  ExpandMore,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import SwitcheButton from "../SwitchButton/SwitchButton";
import "./styles.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import CaptionFormCard from "../CaptionFormCard/CaptionFormCard";
import CaptionFormCard2 from "../CaptionFormCard2/CaptionFormCard2";
import CaptionFormCardClient from "../CaptionFormCard3/CaptionFormCardClient";
import ListItemEducationIndex from "../LeftSide/ListItemsEducationIndex/ListItemEducationIndex";
import { HOST_NAME } from "../../config";

const Row = ({ packageInfo, packagesMade, paintID }) => {
  // console.log("packageInfo", packageInfo);
  const [open, setOpen] = useState(false);
  const [packageDetail, setPackageDetail] = useState({});
  const [packageIDSelected, setPackageIDSelected] = useState("");
  const [isLoadingPackage, setIsLoadingPackage] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [packageExercises, setPackageExercises] = useState([]);

  function getPackageExercises(localStorageData) {
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      fetch(
        `${HOST_NAME}/api/v1/Package/${packageInfo.keyID}/Exercises`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          console.log("get exercises res", res);
          return res.json();
        })
        .then((exercises) => {
          console.log("package exercises", exercises);

          getPackageDeviders(localStorageData, exercises);
        })
        .catch((e) => console.log(e));
    }
  }

  function getPackageDeviders(localStorageData, exercises) {
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      fetch(
        `${HOST_NAME}/api/v1/Package/GetPackageDeviders?packageId=${packageInfo.keyID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          console.log("get deviders res", res);
          return res.json();
        })
        .then((data) => {
          data.map((devider) => [
            ...exercises.splice(devider.rowIndex, 0, devider),
          ]);

          console.log("package deviders & exercises", data, exercises);

          setPackageExercises(exercises);
          setIsLoadingPackage(false);
        })
        .catch((e) => console.log(e));
    }
  }

  const getPackageSClient = (id) => {
    setIsLoadingPackage(true);
    if (packageInfo) {
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        const token = parsedData.token;

        fetch(`${HOST_NAME}/api/v1/Package/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            console.log("data", data);
            setPackageDetail(data);
            getPackageExercises(localStorageData);
          })
          .catch((e) => console.log(e));
      }
    }
  };

  const handleExpandClick = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  return (
    <>
      <TableRow
        onClick={() => {
          getPackageSClient(packageInfo.keyID);
          setPackageIDSelected(packageInfo.keyID);
        }}
      >
        <TableCell>
          <IconButton
            size="small"
            onClick={() => setOpen((prevState) => !prevState)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell style={{ fontFamily: "IRANSans" }}>
          {packageInfo.packageName}
        </TableCell>
        {/* <TableCell component="th" scope="row">
            Last Visit
        </TableCell> */}
        <TableCell style={{ fontFamily: "IRANSans" }}>
          {packageInfo.startDate.split("T")[1].split(".")[0]} |{" "}
          {moment(packageInfo.startDate.split("T")[0], "YYYY/MM/DD")
            .locale("fa")
            .format("YYYY/MM/DD")}
        </TableCell>
        {/*    <TableCell style={{ fontFamily : 'IRANSans' }}>{packageInfo.endDate}</TableCell>*/}
        <TableCell>
          <SwitcheButton
            paintID={paintID}
            packageIDSelected={packageInfo.keyID}
            stausShow={packageInfo.isActive}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table aria-label="details">
              <TableBody>
                <TableRow>
                  {packageDetail ? (
                    <>
                      {isLoadingPackage ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            margin: "1.5rem",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        <>
                          <p
                            style={{
                              margin: "1.5rem",
                              color: "#1876d3",
                              fontFamily: "IRANSans",
                              fontSize: "1.7rem",
                            }}
                          >
                            نام پکیج: {packageInfo.packageName}
                          </p>
                          <p
                            style={{
                              margin: "1.5rem",
                              fontFamily: "IRANSans",
                              fontSize: "1.2rem",
                            }}
                          >
                            توضیحات: {packageInfo.packageDescription}
                          </p>

                          {
                            packageExercises.length > 0 ? (
                              <TableContainer component={Paper}>
                                <Table aria-label="collapsible table">
                                  <TableBody>
                                    {packageExercises.map((exercise, index) => (
                                      <>
                                        <TableRow className="simple-card">
                                          {!exercise.fK_PackageID ? (
                                            <>
                                              <TableCell>
                                                <Avatar
                                                  alt={`Avatar ${exercise.keyID}`}
                                                  src={exercise.mainPicturePath}
                                                />
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  fontFamily: "IRANSans",
                                                }}
                                              >
                                                {exercise.name}
                                              </TableCell>
                                              <TableCell>
                                                <IconButton
                                                  aria-label="expand"
                                                  onClick={() => {
                                                    handleExpandClick(index);
                                                  }}
                                                >
                                                  <ExpandMore />
                                                </IconButton>
                                              </TableCell>{" "}
                                            </>
                                          ) : (
                                            <TableCell colSpan={3}>
                                              <p className="devider-title">
                                                {exercise.title}
                                              </p>
                                              <p className="devider-description">
                                                {exercise.instruction}
                                              </p>
                                            </TableCell>
                                          )}
                                        </TableRow>
                                        <TableRow>
                                          <TableCell
                                            style={{
                                              paddingBottom: 0,
                                              paddingTop: 0,
                                            }}
                                            colSpan={6}
                                          >
                                            <Collapse
                                              in={expandedRow === index}
                                              timeout="auto"
                                              unmountOnExit
                                              style={{
                                                padding: "5px",
                                              }}
                                            >
                                              <CaptionFormCardClient
                                                packageID={packageDetail.keyID}
                                                {...exercise}
                                              />
                                            </Collapse>
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            ) : null
                            // <p
                            //   style={{
                            //     fontFamily: "IRANSans",
                            //     fontSize: "1.2rem",
                            //   }}
                            // >
                            //   ورزشی برای این بیمار ست نشده است.
                            // </p>
                          }

                          {packageDetail.educations
                            ? packageDetail.educations.map((education) => (
                                <ListItemEducationIndex
                                  key={education.keyID}
                                  {...education}
                                  isForClient
                                />
                              ))
                            : // <TableContainer
                              //   component={Paper}
                              //   style={{ width: "100%" }}
                              // >
                              //   <TableBody>
                              //     {packageDetail.educations.map((education) => (
                              //       <TableRow key={education.keyId}>
                              //         <TableCell>
                              //           <Avatar>
                              //             {education.mainPicturePath &&
                              //               education.mainPicturePath}
                              //           </Avatar>
                              //         </TableCell>
                              //         <TableCell
                              //           style={{ fontFamily: "IRANSans" }}
                              //         >
                              //           {education.title}
                              //         </TableCell>
                              //         <TableCell
                              //           style={{ fontFamily: "IRANSans" }}
                              //         >
                              //           {education.content}
                              //         </TableCell>
                              //         <TableCell
                              //           style={{ fontFamily: "IRANSans" }}
                              //         >
                              //           {education.subTitle}
                              //         </TableCell>
                              //         <TableCell
                              //           style={{ fontFamily: "IRANSans" }}
                              //         >
                              //           {education.subContent}
                              //         </TableCell>
                              //         <TableCell
                              //           style={{ fontFamily: "IRANSans" }}
                              //         >
                              //           {/* <Button onClick={() => handleOpenModal(row)}>جزییات بیشتر</Button> */}
                              //         </TableCell>
                              //       </TableRow>
                              //     ))}
                              //   </TableBody>
                              // </TableContainer>
                              // <p
                              //   style={{
                              //     fontFamily: "IRANSans",
                              //     fontSize: "1.2rem",
                              //   }}
                              // >
                              //   آموزشی برای این بیمار ست نشده است.
                              // </p>
                              null}
                        </>
                      )}
                    </>
                  ) : null}
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const ExerciseTable = ({ packagesMade, paintID }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="exercise table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell
              style={{
                fontFamily: "IRANSans",
                fontSize: "1.1rem",
                color: "#1876d3",
              }}
            >
              پکیج های ست شده
            </TableCell>
            <TableCell
              style={{
                fontFamily: "IRANSans",
                fontSize: "1.1rem",
                color: "#1876d3",
              }}
            >
              تاریخ شروع{" "}
            </TableCell>
            {/* <TableCell style={{ fontFamily : 'IRANSans', fontSize : '1.1rem' , color: '#1876d3'}}>تاریخ اعتبار</TableCell>*/}
            <TableCell
              style={{
                fontFamily: "IRANSans",
                fontSize: "1.1rem",
                color: "#1876d3",
              }}
            >
              نمایش در پورتال{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {packagesMade.map((pk) => (
            <Row
              key={pk.keyID}
              packageInfo={pk}
              packagesMade={packagesMade}
              paintID={paintID}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const AccordionItem = (card) => {
  const [isMoreDetailsOpen, setIsMoreDetailsOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            size="small"
            onClick={() => setIsMoreDetailsOpen((prevState) => !prevState)}
          >
            {isMoreDetailsOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Avatar>{card.mainPicturePath && card.mainPicturePath}</Avatar>
        </TableCell>
        <TableCell style={{ fontFamily: "IRANSans" }}>{card.name}</TableCell>
        <TableCell style={{ fontFamily: "IRANSans" }}>
          {card.subject1}
        </TableCell>
        <TableCell style={{ fontFamily: "IRANSans" }}>
          {/* {card.preparation} */}
        </TableCell>
        <TableCell style={{ fontFamily: "IRANSans" }}>
          {card.subject2}
        </TableCell>
        <TableCell style={{ fontFamily: "IRANSans" }}>
          {/* {card.execution} */}
        </TableCell>
        <TableCell style={{ fontFamily: "IRANSans" }}>
          {/* <Button
      onClick={() => handleOpenModal(row)}
    >
      جزییات بیشتر
    </Button> */}
        </TableCell>
      </TableRow>
      {isMoreDetailsOpen && (
        <TableRow>
          <TableCell colSpan={12}>
            <Collapse in={isMoreDetailsOpen} timeout="auto" unmountOnExit>
              {card.preparation && card.execution ? (
                <div className="more-details">
                  <ul
                    dangerouslySetInnerHTML={{
                      __html: FormatExecutionString(card.preparation),
                    }}
                  ></ul>
                  <ul
                    dangerouslySetInnerHTML={{
                      __html: FormatExecutionString(card.execution),
                    }}
                  ></ul>
                </div>
              ) : (
                <p className="no-more-details">
                  جزییاتی برای این تمرین وجود ندارد
                </p>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ExerciseTable;
