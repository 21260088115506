import React, { useContext } from "react";
import "./Subscription.css";
import { FormatExecutionString } from "../../utils";
import { SubscriptionContext } from "../../Contexts/Subscriptions";
import { useNavigate } from "react-router-dom";

export default function Subscription({
  subject,
  description,
  price,
  discount,
  days,
  listOrder,
  keyID,
}) {
  const image = `/images/exercise${listOrder}.webp`;
  const descriptionOptions = FormatExecutionString(description);
  const { choosedSubscriptions, setChoosedSubscriptions } =
    useContext(SubscriptionContext);

  const navigate = useNavigate();

  function calculateDiscountedPrice() {
    const discountTotal = (discount / 100) * price;

    return price - discountTotal;
  }

  function chooseSubscription() {
    setChoosedSubscriptions([{ subject, price, discount, image, keyID }]);

    navigate("/basket");
  }

  return (
    <div className="subscription">
      {discount ? <div className="discount">{discount}%</div> : null}
      <div>
        <img src={image} alt="فرد در حال ورزش" />
        <div className="centered-content">
          <h4>{subject}</h4>

          {discount ? (
            <>
              <span className="old-price">
                {price ? `${price.toLocaleString()} تومان` : "رایگان"}
              </span>
              <div className="current-price">
                {calculateDiscountedPrice().toLocaleString()}{" "}
                <span className="toman">تومان</span>
              </div>
            </>
          ) : price ? (
            <div className="current-price">
              {price.toLocaleString()} <span className="toman">تومان</span>
            </div>
          ) : (
            <div className="current-price">رایگان</div>
          )}
        </div>
        <ul dangerouslySetInnerHTML={{ __html: descriptionOptions }}></ul>
      </div>
      <button onClick={chooseSubscription}>انتخاب تعرفه</button>
    </div>
  );
}
