import { useState, useEffect } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React from "react";
import SelectBox from "../SelectBox/SelectBox";
import { Cancel } from "@mui/icons-material";
import Swal from "sweetalert2";
import { HOST_NAME } from "../../config";

export default function NewTicketModal({
  children,
  setIsNewTicketBoxOpen,
  isNewTicketBoxOpen,
  receiverID,
  getAllTickets,
}) {
  const ticketInitialValue = {
    subject: "",
    content: "",
  };

  const [newTicket, setNewTicket] = useState(ticketInitialValue);

  function closeNewTicketBox() {
    setIsNewTicketBoxOpen(false);
    setNewTicket(ticketInitialValue);
  }

  function sendNewTicket(e) {
    if (receiverID) {
      e.preventDefault();

      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        const token = parsedData.token;

        const newTicketObj = {
          keyID: receiverID === 1 ? 1 : 0,
          subject: newTicket.subject,
          description: "string",
          reciverID:
            receiverID === 1
              ? "aff9e532-d603-410d-8d7d-000000000000"
              : receiverID,
          priority: 0,
          status: 0,
          newMessage: newTicket.content,
        };

        console.log("new ticket object", newTicketObj);

        fetch(`${HOST_NAME}/api/v1/Ticket`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newTicketObj),
        })
          .then((res) => {
            Swal.fire({
              title: "تیکت با موفقیت ارسال شد",
              icon: "success",
            });

            setNewTicket(ticketInitialValue);
            getAllTickets();
            closeNewTicketBox();
          })
          .catch((error) => console.log("send meessage error", error));
      }
    } else {
      Swal.fire({
        title: "لطفا گیرنده تیکت رو مشخص کنید",
        icon: "info",
      });
    }
  }

  return (
    <Dialog
      className="new-ticket-modal"
      open={isNewTicketBoxOpen}
      onClose={closeNewTicketBox}
    >
      <Box className="top-new-ticket">
        <DialogTitle
          style={{
            fontFamily: "IRANSans",
            fontSize: "2rem",
          }}
        >
          تیکت جدید
        </DialogTitle>
        <IconButton
          className="btn-close-modal"
          style={{ marginLeft: "1rem" }}
          onClick={closeNewTicketBox}
        >
          <Cancel style={{ color: "rgba(176,56,60,255)", fontSize: "2rem" }} />
        </IconButton>
      </Box>

      <DialogContent>
        {children}
        <FormControl fullWidth margin="normal">
          <TextField
            label="موضوع"
            name="subject"
            value={newTicket.subject}
            onChange={(e) =>
              setNewTicket({
                ...newTicket,
                subject: e.target.value,
              })
            }
            variant="outlined"
            fullWidth
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="توضیحات"
            name="content"
            value={newTicket.content}
            onChange={(e) =>
              setNewTicket({
                ...newTicket,
                content: e.target.value,
              })
            }
            variant="outlined"
            fullWidth
            multiline
            rows={4}
          />
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={sendNewTicket} className="btn-send">
          ارسال پیام
        </Button>
      </DialogActions>
    </Dialog>
  );
}
