import * as React from "react";
import { styled, FormGroup, FormControlLabel, Switch } from "@mui/material";
import "./SwitchButton.css";
import { Android12Switch } from "../../utils";
import { HOST_NAME } from "../../config";

export default function SwitcheButton({
  stausShow,
  paintID,
  packageIDSelected,
}) {
  const handleChange = (event) => {
    const todayDate = new Date();
    const newStatus = event.target.checked;

    const localStorageData = localStorage.getItem("user");
    const newStatusActivePackage = {
      fK_PackageId: packageIDSelected,
      isActive: newStatus,
      startDate: todayDate.toISOString(),
      enddate: new Date(
        todayDate.getFullYear() + 1,
        todayDate.getMonth(),
        todayDate.getDate()
      ).toISOString(),
    };

    console.log("new status", newStatusActivePackage);

    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      fetch(`${HOST_NAME}/api/v1/Patient/${paintID}/Packages`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newStatusActivePackage),
      })
        // .then(res => res.json())
        .then((result) => console.log(result))
        .catch((error) => {
          console.error("Error:", error);
        });
    }

    // انتقال وضعیت به کامپوننت والد اگر نیاز دارید
    // this.props.onSwitchChange(newStatus);
  };
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Android12Switch defaultChecked={stausShow} onChange={handleChange} />
        }
      />
    </FormGroup>
  );
}
