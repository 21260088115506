import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Slide,
  IconButton,
  Box,
} from "@mui/material";

import {
  Close,
  LocalPostOffice,
  AssignmentInd,
  Send,
} from "@mui/icons-material";

import "./ModalIconSend.css";
import { format } from "date-fns-jalali";

// contexts
import { SelectedClientContext } from "../../../Contexts/SelectedClientContext";
import { PackagesMadeContext } from "../../../Contexts/PackagesMade";
import { PackageID } from "../../../Contexts/PackageID";
import ClientRightSide from "../../ClientRightSide/ClientRightSide";
import Swal from "sweetalert2";
import { HOST_NAME } from "../../../config";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalIconSend({
  getPackageNme,
  coverContent,
  setIsTabPanelOpen,
}) {
  const [dataForShowInformation, setDataForShowInformation] = useState(null);
  const [defaultDate, setDefaultDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [isShowSendButton, setIsShowSendButton] = useState(false);

  const { packageID } = useContext(PackageID);
  const { selectedClient, setSelectedClient } = useContext(
    SelectedClientContext
  );

  // LeftSide
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [signature, setSignature] = useState("");
  const [date, setDate] = useState(defaultDate);
  const [finalData, setFinalData] = useState(null);
  const [finalSubmit, setFinalSubmit] = useState(false);

  // عملیات ذخیره سازی
  const { packagesMade, setPackagesMade, exerciseParameters } =
    useContext(PackagesMadeContext);

  function sendParametersToPackage() {
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;
      exerciseParameters.forEach((exercise) => {
        fetch(
          `${HOST_NAME}/api/v1/Package/${packageID}/Exercises/${exercise.exerciseID}/Parameters`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(
              exercise.params.map(
                ({ keyID, paramName, paramValue, paramType, isDefault }) => ({
                  keyID,
                  paramName,
                  paramValue,
                  paramType,
                  isDefault,
                })
              )
            ),
          }
        ).then((res) => console.log("res send params to clients", res));
      });
    }
  }

  const postCardForPackage = (id) => {
    const localStorageData = localStorage.getItem("user");

    const exerciseList = packagesMade.exercises
      ?.filter((card) => !card.fK_TemplateID)
      .map((card, index) => ({
        exerciseID: card.keyID,
        status: index,
        isCustomized: card.isCustomized,
        type: card.type,
      }))
      .filter((card) => card.type !== "devider");

    const deviders = packagesMade.exercises
      ?.map((card, index) => ({
        keyID: 0,
        fK_PackageID: packageID,
        title: card.contentTitle,
        instruction: card.contentDescription,
        rowIndex: index,
        type: card.type,
      }))
      .filter((devider) => devider.type === "devider");

    const editingDeviders = packagesMade.exercises
      .map((card, index) => ({
        keyID: card.keyID,
        fK_PackageID: card.fK_TemplateID,
        title: card.title || "",
        instruction: card.instruction || "",
        rowIndex: index,
        type: card.type,
      }))
      ?.filter((devider) => devider.fK_PackageID)
      .map((devider) => ({ ...devider, fK_PackageID: packageID, keyID: 0 }));

    const educationList = packagesMade.educations?.map(
      (education) => education.keyID
    );

    console.log("deviders", deviders);
    console.log("exerciseList", exerciseList);
    console.log("educationList", educationList);

    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      fetch(`${HOST_NAME}/api/v1/Package/${packageID}/Exercises`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(exerciseList),
      })
        .then((result) => sendParametersToPackage())
        .catch((error) => {
          console.error("Error:", error);
        });

      [...deviders, ...editingDeviders].forEach((devider) => {
        console.log("devider test", devider);
        fetch(`${HOST_NAME}/api/v1/Package/PostDevider`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(devider),
        })
          .then((result) => console.log("res devider", result))
          .catch((error) => {
            console.error("Error:", error);
          });
      });

      fetch(
        `${HOST_NAME}/api/v1/Package/${packageID}/Educations`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(educationList),
        }
      )
        .then((result) => console.log(result))
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  function editPackageName(id) {
    const editedPackage = {
      keyID: packageID,
      packageName: coverContent.contentTitle,
      packageDescription: coverContent.contentDescription,
    };

    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      // postCardForPackage();

      fetch(`${HOST_NAME}/api/v1/Package`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedPackage),
      })
        .then((response) => {
          postCardForPackage();
          handlSubmitInformation(id);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  const [open, setOpen] = useState(false);

  const handlSubmitInformation = (id) => {
    const finalData = {
      subject,
      message,
      signature,
      date,
    };

    setFinalData(finalData);
    const todayDate = new Date();

    const clientSelected = {
      keyID: 0,
      fK_PatientIDlist: [id],
      startDate: todayDate.toISOString(),
      enddate: new Date(
        todayDate.getFullYear() + 1,
        todayDate.getMonth(),
        todayDate.getDate()
      ).toISOString(),
      status: 0,
      infoSubject: finalData.subject || "string",
      infoText: finalData.message || "string",
      senddate: "2023-10-11T17:30:43.154Z",
      sendType: 0,
    };

    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      fetch(`${HOST_NAME}/api/v1/Package/${packageID}/Patients`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(clientSelected),
      })
        .then((res) => {
          Swal.fire({
            title: "پکیج با موفقیت ارسال شد",
            icon: "success",
          });
          setIsTabPanelOpen(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const [isLodingGetPackageClient, setIsLodingGetPackageClient] =
    useState(false);

  const [openAlert, setOpenAlert] = React.useState(false);

  const clientRightSideProps = {
    setDataForShowInformation,
    dataForShowInformation,
    setIsLodingGetPackageClient,
    setSelectedClient,
  };

  function checkCoverContent() {
    if (coverContent.contentTitle === "" || !Object.keys(coverContent).length) {
      Swal.fire({
        title: "خطا",
        icon: "error",
        text: "از قسمت کاور نام پکیج خود را وارد کنید.",
      });
    } else if (
      !packagesMade.educations.length &&
      !packagesMade.exercises.length
    ) {
      Swal.fire({
        title: "خطا",
        icon: "error",
        text: "حداق یک تمرین یا آموزش به پکیج اضافه کنید",
      });
    } else {
      setOpen(true);
    }
  }

  return (
    <div>
      <div
        onClick={checkCoverContent}
        style={{ display: "flex", alignItems: "center" }}
      >
        <IconButton>
          <LocalPostOffice fontSize="large" />
        </IconButton>

        <p style={{ fontSize: "1.2rem" }}>ارسال</p>
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", backgroundColor: "white" }}>
          <Toolbar>
            <IconButton
              edge="start"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <Close fontSize="large" />
            </IconButton>
            <Typography
              sx={{ ml: 1, flex: 1, fontFamily: "IRANSans", color: "black" }}
              variant="h6"
              component="div"
            >
              بستن
            </Typography>
            {isShowSendButton ? (
              <Button
                autoFocus
                color="inherit"
                onClick={() => {
                  editPackageName(dataForShowInformation.keyID);
                  setOpen(false);
                }}
                style={{ backgroundColor: "green" }}
              >
                <LocalPostOffice
                  fontSize="large"
                  style={{ color: "white", margin: "1rem" }}
                />
                <p style={{ color: "white" }}>ارسال</p>
              </Button>
            ) : null}
          </Toolbar>
        </AppBar>

        <div className="mainSection" style={{ backgroundColor: "#f8f8f8" }}>
          <ClientRightSide {...clientRightSideProps} />
          {/* سمت چپ :جزییات اطلاعات بیمارن */}
          <div style={{ flex: "4", height: "100vh" }}>
            {dataForShowInformation ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {/* نمایش اطلاعات */}
                <div>
                  <pre>
                    <AssignmentInd fontSize="large" />{" "}
                    {dataForShowInformation.fName}{" "}
                    {dataForShowInformation.lName}
                  </pre>
                  <p style={{ fontSize: "1rem", margin: "0.5rem" }}>
                    آدرس ایمیل : {dataForShowInformation.email}
                  </p>

                  <p style={{ fontSize: "1rem", margin: "0.5rem" }}>
                    شماره تلفن : {dataForShowInformation.mobile1}
                  </p>

                  <p style={{ fontSize: "1rem", margin: "0.5rem" }}>
                    علت مراجعه : {dataForShowInformation.description}
                  </p>
                  <hr />
                </div>

                <div>
                  <Box
                    sx={{
                      width: 500,
                      maxWidth: "100%",
                    }}
                  >
                    {/*  <div style={{ display: 'flex', alignItems: 'center', margin: '2rem' }}>
                              <TextField fullWidth label="موضوع" id="fullWidth" helperText='لطفا وارد کنید موضوع پیام خود را'
                               value={subject}
                               onChange={(e) => setSubject(e.target.value)}/>
                            </div> 

                            <div style={{ display: 'flex', alignItems: 'center', margin: '2rem'  }}>
                              <TextField 
                              fullWidth label="پیام" 
                              id="fullWidth" 
                              helperText='لطفا وارد کنید پیام خود را' 
                              defaultValue='برنامه ورزشی شما آماده است:'
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}/>
                            </div>*/}

                    {/* <div style={{ display: 'flex', alignItems: 'center', margin: '2rem'  }}>
                              <TextField 
                              fullWidth 
                              label="امضا" 
                              id="fullWidth" 
                              helperText='محل درج امضا'
                              value={signature}
                              onChange={(e) => setSignature(e.target.value)}/>
                          </div>

                            <div style={{ display: 'flex', alignItems: 'center', margin: '2rem'  }}>
                              <TextField 
                              fullWidth label="تاریخ" 
                              id="fullWidth"
                             defaultValue={defaultDate}
                             value={date}
                             onChange={(e) => setDate(e.target.value)}/>
                            </div>*/}
                  </Box>
                </div>

                <Button
                  variant="contained"
                  startIcon={<Send style={{ color: "white" }} />}
                  onClick={() => {
                    setIsShowSendButton((prevState) => !prevState);
                    // handleSendPackage();
                    // handlSubmitInformation(dataForShowInformation.keyID);
                    setFinalSubmit(true);
                  }}
                >
                  <p style={{ color: "white" }}>اطلاعات را تایید میکنید ؟</p>
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
